// If you don't want to use TypeScript you can delete this file!
//  @ts-ignore
import React, { useEffect } from "react";
import "../../style/index.scss";
import "../../style/menu.scss";
import "../../style/panorama.scss";
import "../../style/popup.scss";
import "../../style/panel.scss";
import { PageProps, graphql } from "gatsby";
import SEO from "../../components/seo";
import PanoramaContainer from "../../components/panorama-container";
import { isMobile } from "react-device-detect";
import GoogleTagManager from "../../components/google-tag-manager";
import { LOCALSTORAGE_LANGUAGE } from "../../components/consts";

type DataProps = {
  mobileImages: {
    nodes: [
      {
        name: string;
        childImageSharp: {
          fluid: {
            src: string;
          };
        };
      }
    ];
  };
  desktopImages: {
    nodes: [
      {
        name: string;
        childImageSharp: {
          fluid: {
            src: string;
          };
        };
      }
    ];
  };
};

const Index: React.FC<PageProps<DataProps>> = ({ data }) => {
  let panoramaImages = {};
  if (isMobile) {
    data.mobileImages.nodes.forEach((f) => {
      panoramaImages[f.name] = f.childImageSharp.fluid.src;
    });
  } else {
    data.desktopImages.nodes.forEach((f) => {
      panoramaImages[f.name] = f.childImageSharp.fluid.src;
    });
  }
  // data.allFile.nodes.forEach(f => {
  //   panoramaImages[f.name] = f.childImageSharp.fluid.src
  // })

  useEffect(() => {
    localStorage.setItem(LOCALSTORAGE_LANGUAGE, "fr");
  }, []);

  return (
    <>
      <SEO title="Using TypeScript" />
      <GoogleTagManager />
      <PanoramaContainer panoramaImages={panoramaImages} />
    </>
  );
};

export const query = graphql`
  {
    mobileImages: allFile(filter: { relativeDirectory: { eq: "views" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 6500) {
            src
          }
        }
      }
    }
    desktopImages: allFile(filter: { relativeDirectory: { eq: "views" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 10000) {
            src
          }
        }
      }
    }
  }
`;

export default Index;
